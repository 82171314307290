import React from 'react';
import { Heading2 } from './heading';

export const Services = () => {
    return (
        <div className="services" id='services'>
            <Heading2 heading="Services" subheading="Skill-Set"/>
            <div className="cards">
                <div className="card-1">
                    <div className="card-icon">
                        <i class='bx bxl-html5 card-skill-icon'></i>
                    </div>

                    <div className="card-heading">
                        <p className='fw-bold'>Html</p>       
                    </div>

                    <div className="card-para">
                        <p>Building the structure of web pages with clean, semantic markup</p>   
                    </div>
                </div>

                <div className="card-1">
                    <div className="card-icon">
                        <i class='bx bxs-file-css card-skill-icon' ></i>
                    </div>

                    <div className="card-heading">
                        <p className='fw-bold'>Css</p>       
                    </div>
                    
                    <div className="card-para">
                        <p>Styling and designing responsive, visually appealing layouts using modern techniques.</p>   
                    </div>
                </div>

                <div className="card-1">
                    <div className="card-icon">
                        <i class='bx bxs-file-js card-skill-icon'></i>
                    </div>

                    <div className="card-heading">
                        <p className='fw-bold'>JavaScript</p>       
                    </div>
                    
                    <div className="card-para">
                        <p>Adding interactivity and dynamic functionality to web applications.</p>   
                    </div>
                </div>

                <div className="card-1">
                    <div className="card-icon">
                        <i class='bx bxl-react card-skill-icon'></i>
                    </div>

                    <div className="card-heading">
                        <p className='fw-bold'>ReactJs</p>       
                    </div>
                    
                    <div className="card-para">
                        <p>Developing dynamic and reusable components for modern, responsive UIs.</p>   
                    </div>
                </div>

                <div className="card-1">
                    <div className="card-icon">
                        <i class='bx bxl-bootstrap card-skill-icon'></i>
                    </div>

                    <div className="card-heading">
                        <p className='fw-bold'>Bootstrap</p>       
                    </div>
                    
                    <div className="card-para">
                        <p>Creating sleek, responsive designs with ready-to-use frameworks.</p>   
                    </div>
                </div>

                <div className="card-1">
                    <div className="card-icon">
                        <i class='bx bxl-typescript card-skill-icon'></i>
                    </div>

                    <div className="card-heading">
                        <p className='fw-bold'>TypeScript</p>       
                    </div>
                    
                    <div className="card-para">
                        <p>Enhancing JavaScript with static typing for scalable, error-free code.</p>   
                    </div>
                </div>

                

               
                
            </div>
        </div>
    )
}