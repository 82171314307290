import React from 'react';

export const ProjectCard = (props) => {
    return (
        <div className="projects-card">
            <p className="h4 projects-card-heading fw-bold">{props.heading}</p>
            <div className="projects-img"><img src={props.imglink} alt="Project" className='projects-img'/></div>
            <p className="project-description">{props.description}</p>
            <a href={props.link}><button className="project-preview-btn">Preview</button></a>
        </div>
    )
}