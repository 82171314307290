import React from 'react';
import { ProjectCard } from './projects-card';
import travelimage from '../media/travel-image.png';
import instaclone from '../media/instagram-clone.png'
import portfolio from '../media/portfolio-one.png'

export const Projects = (props) => {
    return (
        <div id="projects">
            <div className="projects-child">
                <ProjectCard heading="Travel website" imglink={travelimage} link="https://samridhdhatour.com/" description ="Responsive travel website built with HTML, CSS, JavaScript, and Bootstrap"/>
                <ProjectCard heading="Portfolio website" imglink={portfolio} link="https://main.d2inmpmj66grws.amplifyapp.com/"/>
                <ProjectCard heading="Instagram Clone"imglink={instaclone} link="https://main.d26grhejmhoq19.amplifyapp.com/"/>
            </div>
        </div>
    )
}