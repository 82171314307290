import React from 'react';

export const Heading = () => {
    return (
        <div className="heading-parent" id='heading-parent'>
            <div className='heading'>
                <p className="h1 display-1">
                    Hello, I am a <br/> 
                    Front end Developer
                </p>
                <figcaption className='subheading-1 fw-bold'> I craft responsive and user-friendly web interfaces</figcaption>
                <button className="creatorjourney-btn fw-bold">Creator Journey <span><i class='bx bx-right-arrow-alt'></i></span> </button>
            </div>

            <div className="timeline">
                <div className="circle-1 top"></div>
                <div className="line"></div>
                <div className="circle-1 middle"></div>
                <div className="line"></div>
                <div className="circle-1 bottom"></div>
            </div>
        </div>
        
    )
}

export const Heading2 = (props) => {
    return (
        <div className="heading-2-parent">
            <div className="heading-2">
                <p className='fw-bolder'>{props.heading}</p>
            </div>
            <div className="subheading-2">
                <p className="h1 display-3 fw-bolder">{props.subheading}</p>
            </div>
            <div className="underline"></div>
        </div>
    )
}