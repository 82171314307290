import React from 'react';

export const Navbar = () => {
    return ( 
        <nav>
            <div className="navbar">
                <a href="#heading-parent" className='nav-link fw-bolder'>Home</a>
                <a href="#services" className='nav-link fw-bolder'>Skills</a>
                <a href="#projects" className='nav-link fw-bolder'>Projects</a>
            </div>
        </nav>
        
    )
}