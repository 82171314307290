import React from 'react';

export const Lastpage = () => {
    return (
        <div className="lastpage">
            <p className='h2 display-4 fw-bold'>Connect With Me</p>
            <input type="text" className='input' typeof='email' placeholder='Email'/>
            <input type="text" className='input' typeof='text' placeholder='Message'/>
            <div className='stay-connected-container'> 
                <button className="stay-connected-button">Stay Connected <i class='bx bx-caret-right'></i></button>
            </div>
        </div>
    )
}