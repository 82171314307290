import React from 'react';
import './App.css';
import './media.css';
import { Heading } from './components/heading';
import { Navbar } from './components/navbar';
import { Services } from './components/services';
import { Projects } from './components/projects';
import { Lastpage } from './components/last-page';

function App() {
  return (
    <>
    <Navbar/>
    <Heading/>
    <Services/>
    {/* <Heading2 heading="Projects" subheading="Projects I made"/> */}
    <Projects/>
    <Lastpage/>
    </>
  );
}

export default App;
